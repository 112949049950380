exports.components = {
  "component---src-modules-lodging-supplier-page-supplier-pagev-2-jsx": () => import("./../../../src/modules/lodging/supplierPage/supplierPagev2.jsx" /* webpackChunkName: "component---src-modules-lodging-supplier-page-supplier-pagev-2-jsx" */),
  "component---src-modules-package-package-page-index-jsx": () => import("./../../../src/modules/package/packagePage/index.jsx" /* webpackChunkName: "component---src-modules-package-package-page-index-jsx" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-templates-entry-template-fullwidth-jsx": () => import("./../../../src/templates/entry.template.fullwidth.jsx" /* webpackChunkName: "component---src-templates-entry-template-fullwidth-jsx" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "slice---src-modules-cards-cards-slice-tsx": () => import("./../../../src/@modules/cards/cards.slice.tsx" /* webpackChunkName: "slice---src-modules-cards-cards-slice-tsx" */),
  "slice---src-modules-logos-grid-logos-grid-slice-tsx": () => import("./../../../src/@modules/logosGrid/logosGrid.slice.tsx" /* webpackChunkName: "slice---src-modules-logos-grid-logos-grid-slice-tsx" */),
  "slice---src-slices-blank-index-tsx": () => import("./../../../src/@slices/blank/index.tsx" /* webpackChunkName: "slice---src-slices-blank-index-tsx" */),
  "slice---src-slices-modules-booking-widget-index-tsx": () => import("./../../../src/@slices/modules/bookingWidget/index.tsx" /* webpackChunkName: "slice---src-slices-modules-booking-widget-index-tsx" */),
  "slice---src-slices-modules-bundle-section-index-tsx": () => import("./../../../src/@slices/modules/bundleSection/index.tsx" /* webpackChunkName: "slice---src-slices-modules-bundle-section-index-tsx" */),
  "slice---src-slices-modules-calendar-index-tsx": () => import("./../../../src/@slices/modules/calendar/index.tsx" /* webpackChunkName: "slice---src-slices-modules-calendar-index-tsx" */),
  "slice---src-slices-modules-cart-section-index-tsx": () => import("./../../../src/@slices/modules/cartSection/index.tsx" /* webpackChunkName: "slice---src-slices-modules-cart-section-index-tsx" */),
  "slice---src-slices-modules-commerce-index-tsx": () => import("./../../../src/@slices/modules/commerce/index.tsx" /* webpackChunkName: "slice---src-slices-modules-commerce-index-tsx" */),
  "slice---src-slices-modules-completed-section-index-tsx": () => import("./../../../src/@slices/modules/completedSection/index.tsx" /* webpackChunkName: "slice---src-slices-modules-completed-section-index-tsx" */),
  "slice---src-slices-modules-cta-index-tsx": () => import("./../../../src/@slices/modules/cta/index.tsx" /* webpackChunkName: "slice---src-slices-modules-cta-index-tsx" */),
  "slice---src-slices-modules-factbox-section-index-tsx": () => import("./../../../src/@slices/modules/factboxSection/index.tsx" /* webpackChunkName: "slice---src-slices-modules-factbox-section-index-tsx" */),
  "slice---src-slices-modules-faq-standard-index-tsx": () => import("./../../../src/@slices/modules/faqStandard/index.tsx" /* webpackChunkName: "slice---src-slices-modules-faq-standard-index-tsx" */),
  "slice---src-slices-modules-feed-list-item-default-index-tsx": () => import("./../../../src/@slices/modules/feedListItem-default/index.tsx" /* webpackChunkName: "slice---src-slices-modules-feed-list-item-default-index-tsx" */),
  "slice---src-slices-modules-full-image-index-tsx": () => import("./../../../src/@slices/modules/fullImage/index.tsx" /* webpackChunkName: "slice---src-slices-modules-full-image-index-tsx" */),
  "slice---src-slices-modules-hero-index-tsx": () => import("./../../../src/@slices/modules/hero/index.tsx" /* webpackChunkName: "slice---src-slices-modules-hero-index-tsx" */),
  "slice---src-slices-modules-iframes-index-tsx": () => import("./../../../src/@slices/modules/iframes/index.tsx" /* webpackChunkName: "slice---src-slices-modules-iframes-index-tsx" */),
  "slice---src-slices-modules-image-carousel-index-tsx": () => import("./../../../src/@slices/modules/imageCarousel/index.tsx" /* webpackChunkName: "slice---src-slices-modules-image-carousel-index-tsx" */),
  "slice---src-slices-modules-live-conditions-index-tsx": () => import("./../../../src/@slices/modules/liveConditions/index.tsx" /* webpackChunkName: "slice---src-slices-modules-live-conditions-index-tsx" */),
  "slice---src-slices-modules-login-register-section-index-tsx": () => import("./../../../src/@slices/modules/loginRegisterSection/index.tsx" /* webpackChunkName: "slice---src-slices-modules-login-register-section-index-tsx" */),
  "slice---src-slices-modules-open-status-section-index-tsx": () => import("./../../../src/@slices/modules/openStatusSection/index.tsx" /* webpackChunkName: "slice---src-slices-modules-open-status-section-index-tsx" */),
  "slice---src-slices-modules-requirements-section-index-tsx": () => import("./../../../src/@slices/modules/requirementsSection/index.tsx" /* webpackChunkName: "slice---src-slices-modules-requirements-section-index-tsx" */),
  "slice---src-slices-modules-review-section-index-tsx": () => import("./../../../src/@slices/modules/reviewSection/index.tsx" /* webpackChunkName: "slice---src-slices-modules-review-section-index-tsx" */),
  "slice---src-slices-modules-search-section-index-tsx": () => import("./../../../src/@slices/modules/searchSection/index.tsx" /* webpackChunkName: "slice---src-slices-modules-search-section-index-tsx" */),
  "slice---src-slices-modules-supplier-list-section-index-tsx": () => import("./../../../src/@slices/modules/supplierListSection/index.tsx" /* webpackChunkName: "slice---src-slices-modules-supplier-list-section-index-tsx" */),
  "slice---src-slices-modules-table-section-index-tsx": () => import("./../../../src/@slices/modules/tableSection/index.tsx" /* webpackChunkName: "slice---src-slices-modules-table-section-index-tsx" */),
  "slice---src-slices-modules-tabs-index-tsx": () => import("./../../../src/@slices/modules/tabs/index.tsx" /* webpackChunkName: "slice---src-slices-modules-tabs-index-tsx" */),
  "slice---src-slices-modules-testimonials-index-tsx": () => import("./../../../src/@slices/modules/testimonials/index.tsx" /* webpackChunkName: "slice---src-slices-modules-testimonials-index-tsx" */),
  "slice---src-slices-modules-text-block-index-tsx": () => import("./../../../src/@slices/modules/textBlock/index.tsx" /* webpackChunkName: "slice---src-slices-modules-text-block-index-tsx" */),
  "slice---src-slices-modules-webcams-section-with-slider-index-tsx": () => import("./../../../src/@slices/modules/webcamsSectionWithSlider/index.tsx" /* webpackChunkName: "slice---src-slices-modules-webcams-section-with-slider-index-tsx" */)
}

